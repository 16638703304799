.carrerContainer {
  h3 {
    font-family: 'Nunito Sans', sans-serif;
    color: #3a2321;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.6;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  p {
    font-family: 'Nunito Sans', sans-serif;
    color: #3a2321;
    font-size: 18px;
    font-weight: 200;
    line-height: 1.6;
    @media only screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
}
.technologies {
  display: flex;
  gap: 8px;
  @media only screen and (max-width: 600px) {
    display: inline;
  }
  .tec {
    max-height: 35px;
  }
}
