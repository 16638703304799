.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: #e0dec7;
  padding: 64px;
  border-radius: 5px;
  position: relative;
  max-width: 70%;
  overflow: auto;
  max-height: 70%;
  @media only screen and (max-width: 600px) {
    padding: 32px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
