.personalContainer {
  display: flex;
  position: relative;
  z-index: 0;
  justify-content: center;
  margin-top: 30px;
  gap: 44px;
  @media only screen and (max-width: 600px) {
    height: 620px;
  }
  .buttonContainer {
    button {
      font-size: 32px;
      border: 0px;
      background: 0px;
      font-weight: 900;
      border: none;
      cursor: pointer;
      outline: none;
      overflow: hidden;
      background-color: transparent;
      position: relative;
      padding-bottom: 18px;
      padding-left: 12px;

      z-index: 2;
      margin: 10px;

      &.aboutMe {
        color: #528629;
      }
      &.carrer {
        color: #f17b2d;
      }

      &.borderAnimation::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 44px;
        width: 6%;
        background-color: #ffae3e;
        transition: width 0.3s ease;
        z-index: 0;
      }

      &.aboutMe::after {
        background-color: #d2ff05;
      }
      &.carrer::after {
        background-color: #ffae3e;
      }

      &.borderAnimation:hover::after {
        width: 100%; /* Border width on hover */
      }
      &.borderAnimation span {
        position: relative; /* Create a stacking context for the text */
        z-index: 1; /* Ensure the text is above the pseudo-element */
      }
    }
  }
}

.imgContainer {
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    position: absolute;
    z-index: 0;
    bottom: 0;
  }
  img {
    max-width: 275px;
    @media only screen and (max-width: 600px) {
      max-width: 260px;
      height: fit-content;
    }
  }
}

.contact {
  display: flex;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }

  p {
    font-family: 'Nunito Sans', sans-serif;
    color: #3a2321;
    font-size: 18px;
  }
}
.linkedInLogo {
  display: flex;
  cursor: pointer;
  img {
    max-width: 32px;
  }
}

.subtitleContainer {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  p {
    font-size: 24px;
    font-weight: 400;
    color: #3a2321;
    margin-bottom: 1rem;
  }
}
