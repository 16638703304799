.aboutmeContainer {
  display: flex;
  gap: 24px;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  .info {
    p {
      font-family: 'Nunito Sans', sans-serif;
      color: #3a2321;
      font-size: 18px;
      font-weight: 200;
      margin: 0px;
      line-height: 1.6;
      @media only screen and (max-width: 600px) {
        font-size: 16px;
      }
    }
    .icons {
      display: flex;
      margin-top: 24px;
      gap: 12px;
      .icon {
        svg {
          max-width: 30px;
          max-height: 30px;
        }
      }
    }
  }

  .image {
    @media only screen and (max-width: 600px) {
      align-self: center;
    }
    img {
      max-width: 220px;
      border-radius: 8px;
    }
  }
}
